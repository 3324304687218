.category-title{
    width:65vw;
    background-color: #f7e3d9;
    color: #866554;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    margin: 10px auto;

}

@media (max-device-width: 420px) {
    .category-title{
        width:80vw;
        background-color: #5b6361;
        color: #dedede;
        padding: 15px 20px 15px 20px;
        font-weight: bold;
        margin: 10px auto;

    }
}